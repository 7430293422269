import {AppIconProps} from './AppIconProps';
import useFeatherIconProps from './useFeatherIconProps';

import React from 'react';

export default function Linkedin(props: AppIconProps): JSX.Element {
  const iconProps = useFeatherIconProps(props);

  return (
    <svg
      fill="none"
      height="32"
      overflow="visible"
      viewBox="0 0 32 32"
      width="32"
      xmlns="http://www.w3.org/2000/svg"
      {...iconProps}
    >
      <circle
        cx="16"
        cy="16"
        fill="#866B75"
        r="16"
      />
      <g clipPath="url(#clip0_1688_6114)">
        <path
          d="M12.2587 13.4885H9.44822C9.32348 13.4885 9.22241 13.5896 9.22241 13.7143V22.7433C9.22241 22.8681 9.32348 22.9691 9.44822 22.9691H12.2587C12.3835 22.9691 12.4845 22.8681 12.4845 22.7433V13.7143C12.4845 13.5896 12.3835 13.4885 12.2587 13.4885Z"
          fill="#ECE8EA"
        />
        <path
          d="M10.8546 9C9.83196 9 9 9.83106 9 10.8526C9 11.8745 9.83196 12.7059 10.8546 12.7059C11.8764 12.7059 12.7077 11.8745 12.7077 10.8526C12.7077 9.83106 11.8764 9 10.8546 9Z"
          fill="#ECE8EA"
        />
        <path
          d="M19.4077 13.2642C18.2789 13.2642 17.4445 13.7494 16.9383 14.3008V13.7144C16.9383 13.5897 16.8373 13.4886 16.7125 13.4886H14.021C13.8962 13.4886 13.7952 13.5897 13.7952 13.7144V22.7434C13.7952 22.8681 13.8962 22.9692 14.021 22.9692H16.8254C16.9501 22.9692 17.0512 22.8681 17.0512 22.7434V18.2761C17.0512 16.7708 17.4601 16.1843 18.5094 16.1843C19.6523 16.1843 19.7431 17.1245 19.7431 18.3536V22.7434C19.7431 22.8682 19.8442 22.9692 19.9689 22.9692H22.7743C22.8991 22.9692 23.0001 22.8682 23.0001 22.7434V17.7909C23.0001 15.5524 22.5733 13.2642 19.4077 13.2642Z"
          fill="#ECE8EA"
        />
      </g>
      <defs>
        <clipPath id="clip0_1688_6114">
          <rect
            fill="white"
            height="14"
            transform="translate(9 9)"
            width="14"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
