import {CSSStyles} from '../../types/CSSStyles';

import {css} from '@emotion/react';
import React from 'react';

export interface Props {
  readonly children: React.ReactNode;
  readonly rootStyle?: CSSStyles;
}

const SECTION_PADDING = 80;
export default function SectionContainer(props: Props): JSX.Element {
  const {children, rootStyle} = props;
  return (
    <div
      css={[
        css({
          paddingBottom: SECTION_PADDING,
          paddingTop: SECTION_PADDING,
        }),
        rootStyle,
      ]}
    >
      {children}
    </div>
  );
}
