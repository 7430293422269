import useAppTheme from '../design_system/hooks/useAppTheme';
import {ContentColor} from '../design_system/theme/useContentPalette';
import UtilityText from '../design_system/typography/UtilityText';
import Flex from '../layout/flex/Flex';

import {css} from '@emotion/react';
import React from 'react';

export interface Props {
  readonly languages: string[];
}

export default function LanguageList(props: Props): JSX.Element {
  const {languages} = props;
  const {palettes, spacing} = useAppTheme();
  return (
    <Flex
      sx={css({
        columnGap: spacing.x4,
      })}
    >
      {languages.map((language, i) => {
        return (
          <Flex
            key={i}
            sx={css({
              backgroundColor: palettes.background.neutral.default,
              padding: spacing.x4,
              borderRadius: '3px',
              boxSizing: 'border-box',
              height: 21,
              width: 21,
              justifyContent: 'center',
            })}
          >
            <UtilityText color={ContentColor.PRIMARY_CONTRAST}>
              {language.toUpperCase()}
            </UtilityText>
          </Flex>
        );
      })}
    </Flex>
  );
}
