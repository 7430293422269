import SectionContainer from './SectionContainer';
import SignupCTAButton from '../design_system/Button/SignupCTAButton';
import useAppTheme from '../design_system/hooks/useAppTheme';
import {ContentColor} from '../design_system/theme/useContentPalette';
import Typography, {Size} from '../design_system/typography/Typography';
import Flex from '../layout/flex/Flex';

import {css} from '@emotion/react';
import React from 'react';
import {Trans, useTranslation} from 'react-i18next';

export default function SectionFinalCTA(): JSX.Element {
  const {palettes, spacing} = useAppTheme();
  const {t} = useTranslation();
  return (
    <div
      css={css({
        backgroundColor: palettes.background.accent.default,
      })}
      data-testid="final-cta-section"
    >
      <SectionContainer>
        <Flex
          sx={css({
            flexDirection: 'column',
            rowGap: spacing.x20,
          })}
        >
          <Typography
            color={ContentColor.PRIMARY_CONTRAST}
            size={Size.XXL}
            weight={700}
          >
            {t('pages.home.section-final-cta-title')}
          </Typography>

          <Typography
            color={ContentColor.PRIMARY_CONTRAST}
            rootStyle={css({
              textAlign: 'center',
            })}
            size={Size.L}
          >
            <Trans i18nKey="pages.home.section-final-cta-description" />
          </Typography>

          <SignupCTAButton />
        </Flex>
      </SectionContainer>
    </div>
  );
}
