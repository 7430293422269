import {AppIconProps} from './AppIconProps';
import useFeatherIconProps from './useFeatherIconProps';

import React from 'react';

export default function Github(props: AppIconProps): JSX.Element {
  const iconProps = useFeatherIconProps(props);

  return (
    <svg
      fill="none"
      height="32"
      overflow="visible"
      viewBox="0 0 32 32"
      width="32"
      xmlns="http://www.w3.org/2000/svg"
      {...iconProps}
    >
      <circle
        cx="16"
        cy="16"
        fill="#866B75"
        r="16"
      />
      <path
        d="M14.1249 22.0417C10.1666 23.2292 10.1666 20.0626 8.58325 19.6667M19.6666 24.4167V21.353C19.6963 20.9755 19.6453 20.596 19.517 20.2397C19.3887 19.8835 19.186 19.5586 18.9224 19.2867C21.4083 19.0096 24.0208 18.0676 24.0208 13.7451C24.0205 12.6397 23.5954 11.5768 22.8333 10.7763C23.1941 9.80929 23.1686 8.74041 22.762 7.79172C22.762 7.79172 21.8278 7.51464 19.6666 8.96339C17.8521 8.47162 15.9394 8.47162 14.1249 8.96339C11.9637 7.51464 11.0295 7.79172 11.0295 7.79172C10.6229 8.74041 10.5974 9.80929 10.9583 10.7763C10.1904 11.5828 9.76483 12.6553 9.77075 13.7688C9.77075 18.0596 12.3833 19.0017 14.8691 19.3105C14.6086 19.5796 14.4078 19.9006 14.2795 20.2525C14.1513 20.6044 14.0986 20.9794 14.1249 21.353V24.4167"
        stroke="#ECE8EA"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  );
}
